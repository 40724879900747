@import 'base';

.zp-subtitulo-principal {
    color: $white;
    font-weight: 600;
    margin-bottom: 0px;
}

.zp-titulo-principal {
    color: $white;
    font-weight: 600;
    font-size: 45px;
}

.zp-texto-principal {
    color: $white;
}

.zp-div-buscador input{
    color: #A0A0A0;
}
.zp-div-buscador {
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: $fourth-color;
    box-shadow: 1px 7px 26px 0 rgb(0 0 0 / 7%);
    z-index: 10;
}

.vs__dropdown-menu{
    color: $fourth-color !important;
} 
.vs__dropdown-option--highlight{ 
  background-color: $main-color !important;
}
.zp-row-destacados{
    width: 100%;
}

.zp-destacados-titulo {
    color: $new-blue-color;
    font-weight: 600;
}
.zp-click{
    cursor: pointer;
}
.zp-destacados-subtitulo {
    color: $main-color;
    font-size: 16px;
}

.zp-destacados-texto {
    color: $third-color;
    text-align: justify;
}

.zp-card-destacados {
    height: 430px;
    margin: 5px;
    //border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
    padding: 5px;
}

.destacados-circle-price {
    border: 1px solid #fff;
    border-radius: 23.5px;
    padding: 5px 35px;
    width: 8rem;
}

.text-transparent {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: thin;
}

.iframe-mac {
    width: 740px;
    height: 500px;
    padding-top: 30px;
}

.zp-card-body-destacados {
    position: absolute;
    bottom: 31px;
    left: 15px;
    font-weight: bolder !important;
    a{
        cursor: pointer;
        text-decoration: none;
    }
    small {
        font-size: 16px;
        line-height: 0.8;
    }
    h6{
        font-size: 19px;
        line-height: 0.8;
    }
    h5 {
        font-size: 28px;
        line-height: 0.8;
        font-weight: 700;
    }
    h4 {
        font-size: 32px;
        line-height: 0.8;
    }
    .zp-card-body-descripcion small{
        font-size: 14px;
    }
    .zp-card-body-descripcion{
        min-height: 48px;
    }
}

.zp-vende-li{
    margin-bottom: 5px;
}

.zp-check-container{
    position: relative;
    float: left;
    margin-bottom: 15px;
}
.zp-check{
    background-image: url("/storage/imgs/iconos/check.svg");
    width: 23px;
    height: 23px;
    background-size: contain;
    position: relative;
    float: left;
    margin-right: 10px;
    top: -4px;
}
.zp-propiedad-ventaje-titulo-pink{
    color: $pink;
    font-weight: 500;
    font-size: 15px;
}

.zp-propiedad-ventaje-titulo-vende{
    color: $new-blue-color;
    font-weight: 600;
    font-size: 45px;
    letter-spacing: -2px;
}
.zp-propiedad-ventaje-titulo {
    color: $new-blue-color;
    font-weight: 600;
    font-size: 45px;
}
.zp-propiedad-ventaje-subtitulo-vende{
    color: $new-blue-color;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -2px;
}
.zp-propiedad-ventaje-subtitulo {
    color: $third-color;
    font-size: 18px;
}

.zp-panel-icon {
    height: 200px;
    margin: 5px 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    h4 {
        font-weight: 600;
        font-size: 24px;
    }
}

.zp-panel-icon-inver {
    background-image: url("/storage/imgs/banner/panel-inversion.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.zp-panel-icon-vivir {
    background-image: url("/storage/imgs/banner/panel-vivir.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.zp-panel-icon-proyectos {
    background-image: url("/storage/imgs/banner/panel-proyectos.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.zp-panel-icon-sustentables {
    background-image: url("/storage/imgs/banner/panel-sustentables.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.zp-panel-icon-unidades {
    background-image: url("/storage/imgs/banner/panel-unidades.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.zp-panel-icon-ventas {
    background-image: url("/storage/imgs/banner/panel-ventas.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.zp-panel-icon-entrega {
    background-image: url("/storage/imgs/banner/panel-entrega.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
}

.zp-formulario-titulo {
    color: $fourth-color;
    font-weight: 600;
    font-size: 18px;
}

.zp-img-ventajas {
    background-image: url("/storage/imgs/otros/home-ventajas.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
    height: 430px;
    border-radius: 6px;
    margin-bottom: 30px;
}

.zp-ventajas-item {
    margin-bottom: 25px;
}

.zp-ventaja-icon {
    display: flex;
    justify-content: center;
    img {
        width: 40%;
    }
}

.zp-ventajas-body {
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    h6 {
        color: $fourth-color;
        font-size: 18px;
        line-height: 1;
        font-weight: 600;
    }
    p{
        color: $fourth-color;
        font-size: 16px;
        line-height: 1;
    }
}

/*Inmobiliarias*/
.zp-inmobiliaria.card{
    border: 0px solid transparent !important;
    padding-top: 4px;
    padding-bottom: 4px;
}
.zp-inmobiliaria .zp-card-container{
    /*margin-left: 32px;
    margin-right: 32px;
    border: 0px solid transparent !important;
    width: 20vw!important;
    opacity: 1;
    border: none;
    text-align: center;
    max-height: 128px;*/

    margin-left: 2rem;
    margin-right: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    /*border: 1px solid rgba(0,0,0,.125);*/
    border-radius: .25rem;
}


.zp-inmobiliaria .zp-card-container:hover{
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    /*box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 1px 7px -3px;*/
}

.zp-inmo-img:hover{
    filter: grayscale(0);
}
.zp-inmo-img{
    height: 8rem;
    width: 12rem;
    max-width: 70%;
    max-width: 192px;
    max-height: 90%;
    padding: 1.25rem;
    filter: grayscale(1);
    margin: 0 auto;
}

.zp-inmobiliarias .owl-stage {
    margin: 0 auto !important;
}

.zp-venta-de-proyectos .owl-nav{
    position: relative;
    left: 0%;
    bottom: 0%;
    margin-top: -12.5rem;
}

.zp-venta-de-proyectos .owl-nav .control-right{
    bottom: 0px;
    right: -4.5rem;
}

.zp-venta-de-proyectos .owl-nav .control-left{
    bottom: 0px;
    left: -4.5rem;
}

.modal-content .close{
    z-index: 1;
}

.zp-pink{
    color:$main-color;
    background-color:$main-color !important;
}
.zp-pink-text{
    color:$main-color !important;
}

/*recuperar contraseña*/
.zp-resetear-contrasena-container{
    padding-top: 130px !important;
    margin-top: 30px;
    margin-bottom: 30px;
}

.zp-fix-float{
    clear: both;
    position: relative;
    float: left;
}
.zp-btn-catalogo {
    border: 1px solid #F946A8;
    color: #F946A8;
    background-color: #FFFFFF;
    border-radius: 3px;
    transition: 0.3s; 
}
.w-60{
    width: 60%;
}
.zp-btn-catalogo:hover {
    color: #FFFFFF;
    background-color: #F946A8;
}
.section-tango {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) -35%, rgba(0, 0, 0, 0.01) 11%, rgba(0, 0, 0, 0.65) 96%, rgba(0, 0, 0, 0.51)),url("/storage/imgs/tango/tango_new.webp");
    background-repeat: no-repeat;
    background-size: cover;
    height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    h2 {
        font-size: 3rem;
    }
    p {
        font-size: 1.5rem;
        font-weight: bold;
    }
    img {
        width: 14rem;
    }
}
.section-newsletter {
    background-image: url("/storage/imgs/otros/newsletter.webp");
    background-repeat: no-repeat;
    background-size: cover;
    height: 39rem;
    display: flex;
    align-items: center;
    padding-bottom: 9rem;
    //flex-direction: column;
    //justify-content: center;
}

.zp-section-buscador {
    margin-top: -5rem;
}

.zp-div-img-slider {
    z-index: -1;
}

.zp-margen-seccion-video {
    margin-top: 8vh;
}

.zp-div-banner-text {
    font-size: 3rem;
    line-height: 1.1;
    img {
        width: 8rem !important;
    }
}

.zp-div-banner-text-slide-2 {
    font-size: 3rem;
    line-height: 1.1;
    img {
        width: 8rem !important;
    }
}

.zp-p-banner-text {
    font-size: 5rem;
    line-height: 1;
    img {
        width: 5.5rem !important;
    }
}

.zp-p-banner-text-slide-2 {
    font-size: 5rem;
    line-height: 1;
}

.zp-div-container-banner {
    width: fit-content;
    justify-content: center;
}

.zp-responsive-img-web {
    display: block;
}

.zp-responsive-img-mobile {
    display: none !important;
}

@media (max-width: 575px) {
    body {
        line-height: normal;
    }

    h2 {
        font-size: 1.7rem;
    }

    .iframe-mac {
        width: 257px;
        height: 172px;
        padding-top: 10px;
    }

    .zp-div-img-slider {
        height: 100%;
        img {
            height: 100%;
        }
    }

    .zp-section-buscador {
        margin-top: 0;
    }

    .zp-div-buscador {
        box-shadow: none;
        border: none;
    }

    .section-tango {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) -35%, rgba(0, 0, 0, 0.01) 11%, rgba(0, 0, 0, 0.65) 96%, rgba(0, 0, 0, 0.51)),url("/storage/imgs/tango/tango_new_mobile.webp");
        background-position: bottom;
        height: 100vh;
        p{
            font-size: 1rem;
            font-weight: normal;
        }
        img {
            width: 10rem;
        }
    }

    .section-newsletter {
        height: 100vh;
    }

    .zp-div-banner-text {
        font-size: 2rem;
        line-height: normal;
        img {
            width: 5rem !important;
        }
    }

    .zp-div-banner-text-slide-2 {
        font-size: 1.4rem;
        line-height: normal;
        img {
            width: 4.5rem !important;
        }
    }

    .zp-p-banner-text-slide-2 {
        font-size: 3rem;
    }
    
    .zp-p-banner-text {
        font-size: 3.5rem;
        line-height: normal;
        img {
            width: 3.5rem !important;
        }
    }

    .zp-div-container-banner {
        width: auto;
        justify-content: end;
    }

    .zp-responsive-img-web {
        display: none !important;
    }
    
    .zp-responsive-img-mobile {
        display: block !important;
    }
}

@media (min-width: 576px) and (max-width: 767px){
    body {
        line-height: normal;
    }

    h2 {
        font-size: 1.7rem;
    }

    .iframe-mac {
        width: 340px;
        height: 230px;
        padding-top: 15px;
    }

    .zp-div-img-slider {
        height: 100%;
        img {
            height: 100%;
        }
    }

    .zp-section-buscador {
        margin-top: 0;
    }

    .zp-div-buscador {
        box-shadow: none;
        border: none;
    }

    .section-tango {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) -35%, rgba(0, 0, 0, 0.01) 11%, rgba(0, 0, 0, 0.65) 96%, rgba(0, 0, 0, 0.51)),url("/storage/imgs/tango/tango_new_mobile.webp");
        background-position: bottom;
        height: 100vh;
        p{
            font-size: 1rem;
            font-weight: normal;
        }
        img {
            width: 10rem;
        }
    }

    .section-newsletter {
        height: 100vh;
    }

    .zp-div-banner-text {
        font-size: 2rem;
        line-height: normal;
        img {
            width: 5rem !important;
        }
    }

    .zp-div-banner-text-slide-2 {
        font-size: 1.4rem;
        line-height: normal;
        img {
            width: 4.5rem !important;
        }
    }

    .zp-p-banner-text-slide-2 {
        font-size: 3rem;
    }
    
    .zp-p-banner-text {
        font-size: 3.5rem;
        line-height: normal;
        img {
            width: 3.5rem !important;
        }
    }

    .zp-div-container-banner {
        width: auto;
        justify-content: end;
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .iframe-mac {
        width: 460px;
        height: 310px;
        padding-top: 20px;
    }

    .zp-div-banner-text-slide-2 {
        font-size: 2rem;
        line-height: normal;
        img {
            width: 4.5rem !important;
        }
    }

    .zp-p-banner-text-slide-2 {
        font-size: 4rem;
    }
}

@media (min-width: 841px) { 
    .zp-margen-seccion-video {
        margin-top: 25vh;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .iframe-mac {
        width: 630px;
        height: 420px;
    }
}
